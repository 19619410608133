.App{
  display: flex;
  flex-direction: column;
  width: 100vw;  
  height: 100vh;
}

.AppHeader{
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px 4px 12px;
  border-bottom: 1px solid color(srgb black black black);
  background-color: gray;
}

.AppFooter{
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.SideBarAndContent{
  display: flex;
  flex:1;
  justify-content: flex-start;
  align-items: flex-start;
}

/* .SideBar{
  display: flex;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  background-color: aqua;
} */

.AppSideBar{
  display: flex;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  height: 100vh;
  width: 8vw;
}

.DashboardPage{
  width: 100vw;
}

.CircleCard{
  background-color: #f0f0f0; /* 设置卡片的背景颜色 */
  display: flex;
  align-items: center;
}

/* width: 110px; /* 设置卡片的宽度 */
/* height: 110px; 设置卡片的高度 */
/* border-radius: 50%; 将边框半径设置为宽度/高度的一半，使其成为圆形 */ 

